import {
  updateTaskStatus,
  UpdateTaskStatus,
  getWaylineJobs,
  createPlan,
  deleteTask,
} from "@/api/wayline.ts";
import { ELocalStorageKey } from "@/types/enums.ts";

export const taskDeleteService = async (jobId) => {
  const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
  const { code } = await deleteTask(workspaceId, {
    job_id: jobId,
  });
  return code
};


// jobId: String
export const taskPauseService = async (jobId) => {
  const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
  const { code } = await updateTaskStatus(workspaceId, {
    job_id: jobId,
    status: UpdateTaskStatus.Suspend,
  });
  if (code === 0) {
    return true;
  } else {
    return false;
  }
};

// jobId: String
export const taskResumeService = async (jobId) => {
  const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
  const { code } = await updateTaskStatus(workspaceId, {
    job_id: jobId,
    status: UpdateTaskStatus.Resume,
  });
  if (code === 0) {
    return true;
  } else {
    return false;
  }
};

export const getJobsService = async () => {

  const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
  const options = {
    page: 1,
    page_size: 500,
    order_by: "update_time desc",
  };
  return await getWaylineJobs(workspaceId, options)
    .then((response) => {
      if (response.code === 0) {
        console.log(response);
        return response.data.list;
      } else {
        return [];
      }
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
};

export const createPlanService = async (planBody) => {
  const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId);
  return await createPlan(workspaceId, planBody)
    .then((response) => {
      console.log(response);
      if (response.code === 0) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};
