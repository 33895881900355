<template>
  <v-list density="compact">
    <v-list-item>
      <v-img :src="`/iso.svg`" aspect-ratio="1"></v-img>
    </v-list-item>
    <v-list-item v-for="(item, index) in routes" :key="index">
      <v-btn :icon="item.icon" @click="routerPush(item)"> </v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "App",
  components: {

  },
  methods: {
    routerPush(item) {
      this.$router.push(item.to);
    },
  },
  setup() {
    const routes = [
      /*{ icon: "mdi-debug-step-over", title: "Home", to: "/debug" },*/
      { icon: "mdi-map", title: "Profile", to: "/tsa" },
      { icon: "mdi-calendar", title: "Waylines", to: "/waylines"},
      { icon: "mdi-map-marker-path", title: "Tasks", to: "/tasks"},
      { icon: "mdi-calendar-clock-outline", title: "Calenda2", to: "/calendar2"},
      /*{ icon: "mdi-calendar", title:"Calendar", to:"/calendar"}*/
    ];
    return {
        routes: routes,
    };
  }
};
</script>

<style>
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
